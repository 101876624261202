import styled from 'styled-components';

export const TermsBar = styled.div`
  background: #ededed;
  width: 100%;
  height: auto;
  text-align: center;
`;

export const Terms = styled.div`
  width: 70%;
  margin: 0 auto;
  padding: 30px;
  text-align: center;
  font-size: 12px;
  line-height: 130%;
  color: #9a9a9a;
  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
    padding: 40px 20px;
  }
`;

export const NoteStyle = styled.p`
  font-size: 12px;
  color: #9a9a9a;
  font-family: 'Gotham-Book', arial, sans-serif;
  margin: 0 0 20px 0;
  padding: 0;
`;
